import homeCss from '../styles/home.css?url';

import NavBar from '~/components/home/NavBar';
import { motion } from 'framer-motion';
import { user } from '~/services/auth.server';
import { useLoaderData } from '@remix-run/react';
import { FlipWords } from '~/components/ui/flip-wrod';
import { Button, Divider, Image } from '@heroui/react';

import { ArrowRightIcon } from 'lucide-react';
import TestimonialSlider from '~/components/home/TestimonialSlider';
import CommunityVideoSection from '~/components/home/CommunityVideo';
import VirtualStagingSection from '~/components/home/VirtualStaging';
import ClientLogo from '~/components/home/ClientLogo';

// import ShakaPlayer from 'shaka-player-react';
// Base styles for media player and provider (~400B).

export const links = () => [{ rel: 'stylesheet', href: homeCss }];

export const loader = async ({ request }) => {
  let response = await user({ request });

  return { user: response };
};

export default function Index() {
  const { user } = useLoaderData();

  const words = ['Faster', 'Smarter', 'Bigger'];

  const images = [
    // 'https://lacasatour.b-cdn.net/images/1712084056787_8591_Peppertree_Way_205_Naples_FL_34114_(6).jpg',
    'https://lacasatour.b-cdn.net/images/1717073656332_22130_Shallowater_Ln_Bonita_Springs_FL_34135_(102)sunset.jpg',
    'https://images.unsplash.com/photo-1564078516393-cf04bd966897?q=80&w=1887&auto=format&fit=crop&ixlib=rb-4.0.3&ixid=M3wxMjA3fDB8MHxwaG90by1wYWdlfHx8fGVufDB8fHx8fA%3D%3D',
    'https://plus.unsplash.com/premium_photo-1661963428055-4b25a7ebd3a9?q=80&w=2016&auto=format&fit=crop&ixlib=rb-4.0.3&ixid=M3wxMjA3fDB8MHxwaG90by1wYWdlfHx8fGVufDB8fHx8fA%3D%3D',
    'https://plus.unsplash.com/premium_photo-1661876290667-0612447870d5?q=80&w=1863&auto=format&fit=crop&ixlib=rb-4.0.3&ixid=M3wxMjA3fDB8MHxwaG90by1wYWdlfHx8fGVufDB8fHx8fA%3D%3D',
  ];

  return (
    <div>
      <NavBar user={user} />

      <div className="space-y-20">
        <div className="grid min-h-screen grid-cols-1  md:grid-cols-2 ">
          {/* VIDEO SECTION (on mobile it appears first + covers full screen height) */}
          <div className="relative order-1 h-screen w-full md:order-2 md:h-auto">
            <div className="absolute -left-10 bottom-32 z-40 w-full">
              <div className="flex w-full max-w-md flex-col space-y-10 rounded-lg bg-white/10 p-6 font-arc shadow-lg backdrop-blur-md">
                {/* Profile Image */}
                <div className="text-3xl capitalize">schedule an apointment Today</div>
                <div className="flex animate-pulse items-center justify-end space-x-2  text-2xl text-gray-800">
                  <p>Place Order</p> <ArrowRightIcon className="h-6 w-6" />
                </div>
              </div>
            </div>
            <iframe
              src="https://iframe.mediadelivery.net/embed/130633/84fab9ef-f950-487d-81f3-2d958456bdd9
         ?loop=true
         &autoplay=true
         &muted=true
        
         &responsive=true
         &playsinline=true"
              className="pointer-events-none absolute left-0 top-0 h-full w-full object-cover"
              allow="accelerometer; gyroscope; autoplay; encrypted-media; picture-in-picture"
              allowFullScreen
              autoPlay="true"
            />
          </div>
          {/* TEXT + IMAGES SECTION */}
          <div className="order-2 flex flex-col items-center justify-center space-y-16 px-8 py-12 text-gray-700 md:order-1 md:py-20 md:pl-20 ">
            <div className="w-full font-abril capitalize">
              <div className="text-5xl md:text-6xl">
                Sell
                <FlipWords
                  duration={2000}
                  words={words}
                  className="font-bold italic text-lime-500"
                />{' '}
                <br />
              </div>
              <div className="text-4xl md:text-5xl">
                with top-tier marketing <span className="italic">pros</span>
              </div>
            </div>
            <div className="w-full space-y-5">
              <Divider />
              <div className="font-arc text-xl capitalize text-gray-700 md:text-2xl">
                Expert Execution, Exceptional Results Every Time.
              </div>
              <Divider />
            </div>
            <div className="w-full">
              <Button className="home-primary-btn" radius="sm" size="lg">
                Place Order Now
              </Button>
            </div>
            <div className="flex w-full flex-wrap items-center justify-center">
              {images.map((image, idx) => (
                <motion.div
                  key={'images' + idx}
                  style={{
                    rotate: Math.random() * 20 - 10,
                  }}
                  whileHover={{
                    scale: 1.1,
                    rotate: 0,
                    zIndex: 100,
                  }}
                  whileTap={{
                    scale: 1.1,
                    rotate: 0,
                    zIndex: 100,
                  }}
                  className="-mr-4 mt-4 flex-shrink-0 overflow-hidden rounded-xl border border-neutral-100 bg-white p-1 dark:border-neutral-700 dark:bg-neutral-800"
                >
                  <Image
                    src={image}
                    alt="bali images"
                    width="500"
                    height="500"
                    className="h-20 w-20 flex-shrink-0 rounded-lg object-cover md:h-40 md:w-40"
                  />
                </motion.div>
              ))}
            </div>
          </div>
        </div>
        <div>
          <ClientLogo />
        </div>
        <div className="space-y-4">
          <h2 className="section-heading text-center">What Our Clients Say</h2>
          <TestimonialSlider />
        </div>
        {/* ============= */}

        <div>
          <CommunityVideoSection />
        </div>
        <div>
          <VirtualStagingSection />
        </div>
      </div>

      <br />
      <br />
      <br />
      <br />
      <br />
      <br />
      <br />
      <br />
      <br />
    </div>
  );
}
