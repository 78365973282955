import { Compare } from '../ui/compare';

export default function VirtualStagingSection() {
  return (
    <div className="container mx-auto space-y-4">
      <div>
        <h2 className="section-heading text-center">No Furniture? No Problem!</h2>
        <p className="text-center text-3xl italic">
          Show Buyers the Possibilities: Harness the Power of Virtual Staging
        </p>
      </div>

      <div className="space-y-2">
        <p className="flex-wrap text-center text-2xl">
          Turn bare rooms into inviting living spaces—without the hassle or cost of traditional
          staging. Our Virtual Staging service instantly showcases your property&apos;s potential
          and helps buyers visualize life inside your listing.
        </p>
      </div>

      <div className="rounded-3xl border border-neutral-200 bg-neutral-100 p-4  px-4 dark:border-neutral-800 dark:bg-neutral-900">
        <Compare
          firstImage="https://lacasatour.b-cdn.net/assets/vr-b.webp"
          secondImage="https://lacasatour.b-cdn.net/assets/vr-a.webp"
          firstImageClassName="object-cover object-left-top"
          secondImageClassname="object-cover object-left-top"
          className="w-full h-full aspect-video"
          slideMode="hover"
        />
      </div>
    </div>
  );
}
