import { Card, CardBody, Image } from '@heroui/react';

export default function CommunityVideoSection() {
  return (
    <div className="container mx-auto space-y-4">
      <div>
        <h2 className="section-heading text-center">Capture the Community</h2>
        <p className="text-center text-3xl italic">Let Your Listings Come to Life!</p>
      </div>

      <div className="grid grid-cols-2 gap-4">
        <div className="space-y-6">
          <span className="flex-wrap text-2xl">
            When it comes to real estate, location is more than just a point on the map
            <span className="font-semibold italic">—it&apos;s a lifestyle</span>
          </span>
          <p className="text-lg">
            Our professionally produced Community Videos give your buyers an immersive look into the
            neighborhood&apos;s personality, amenities, and charm. From local hotspots to scenic
            parks, we highlight everything that makes your listing feel like home.
          </p>
    
            <p className="font-abril text-6xl italic">Why it Matters?</p>
            <div className="grid grid-cols-1 gap-10 ">
              <Card>
                <CardBody>
                  <p className="text-lg font-bold italic font-lato">Stand Out in a Competitive Market</p>
                  <p>
                    Community Videos differentiate your listing from the rest, driving more
                    attention and potential offers.
                  </p>
                </CardBody>
              </Card>
              <Card>
                <CardBody>
                  <p className="text-lg font-bold italic font-lato">Build Credibility & Trust</p>
                  <p>
                    Show you&apos;re the local expert by highlighting key neighborhood features and
                    insider knowledge.
                  </p>
                </CardBody>
              </Card>
              <Card>
                <CardBody>
                  <p className="text-lg font-bold italic font-lato">Save Time & Focus on Serious Buyers</p>
                  <p>
                    Buyers who watch a Community Video come better informed and more excited about
                    the location—reducing wasted showings.
                  </p>
                </CardBody>
              </Card>
            
          </div>
        </div>

        <div className="space-y-4">
          <div className="flex space-x-4">
            <Image
              className="h-80 w-96 object-cover drop-shadow-2xl"
              radius="full"
              src="https://lacasatour.b-cdn.net/assets/com-gifs/cl%20-mobile-gif-2.gif"
            />

            <Image
              radius="none"
              className="h-full rounded-[5rem] object-cover drop-shadow-2xl"
              src="https://lacasatour.b-cdn.net/assets/com-gifs/cl-landscape-1.gif"
            />
          </div>
          <div className="flex space-x-4">
            <Image
              radius="none"
              className="h-full rounded-[5rem] object-cover drop-shadow-2xl"
              src="https://lacasatour.b-cdn.net/assets/com-gifs/cl-landscape-2.gif"
            />

            <Image
              className="h-80 w-96 object-cover drop-shadow-2xl"
              radius="full"
              src="https://lacasatour.b-cdn.net/assets/com-gifs/com-gif-mobile.gif"
            />
          </div>
        </div>
      </div>
    </div>
  );
}
